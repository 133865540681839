<template>
  <div id="agent">
    <v-toolbar
      flat
    >
      <v-btn class="ml-1" :to="'/agentes'"
             icon>
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-toolbar-title>Informacion del agente</v-toolbar-title>
      <v-btn
        dark
        color=primary
        class="mb-2 save-btn"
        @click="saveAgent"
      >
        Guardar
      </v-btn>
    </v-toolbar>

    <div class="customer-info pa-8 d-flex" v-if="agent !== null">
      <div class="col-left">
        <v-card class="pa-4 mb-8" v-if="agentIsEnterprise && agent.businessData !== undefined">
          <h4>Datos Persona Juridica</h4>
          <v-row class="mt-6">
            <v-col>
              <v-text-field label="Razon Social" v-model="agent.businessData.name"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Cuit" v-model="agent.businessData.cuit" :rules="[v => /^\d+$/.test(v) || 'Solo se permiten números']"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Domicilio real" v-model="agent.businessData.address"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Código postal" v-model="agent.businessData.postalCode"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Localidad" v-model="agent.businessData.locality"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Provincia" v-model="agent.businessData.province"></v-text-field> -->
              <v-select label="Provincia" :items="provincesItems" v-model="agent.businessData.province"></v-select>
            </v-col>
            <v-col>
              <v-text-field label="Email" v-model="agent.businessData.email"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Teléfono" v-model="agent.businessData.phone1"></v-text-field> -->
              <vue-tel-input v-model="agent.businessData.phone1" class="vue-tel-input-custom"></vue-tel-input>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4">
          <h4>{{ agentIsEnterprise ? 'Datos representante legal' : 'Datos Persona Fisica'}}</h4>
          <v-row class="mt-6">
            <v-col>
              <v-text-field label="Nombre" v-model="agent.name" @blur="capitalizeName"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Apellido" v-model="agent.lastname" @blur="capitalizeLastName"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="DNI" v-model="agent.dni" :rules="[v => /^\d+$/.test(v) || 'Solo se permiten números']" @input="filterNumbers"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Email" v-model="agent.email"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select label="Nacionalidad" :items="nationalitiesItems" v-model="agent.nationality.id"></v-select>
            </v-col>
            <v-col>
              <v-text-field label="Fecha de Nacimiento (25-05-1990)" v-model="agent.birthDate" type="date"></v-text-field>
              <!--              <v-date-picker v-model="customer.birthDate"></v-date-picker>-->
            </v-col>
            <v-col>
              <!-- <v-text-field label="Estado Civil" v-model="agent.civilStatus"></v-text-field> -->
              <v-select label="Estado civil" :items="estadoCivilItems" v-model="agent.civilStatus"></v-select>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Teléfono" v-model="agent.phone1"></v-text-field> -->
              <vue-tel-input v-model="agent.phone1" class="vue-tel-input-custom"></vue-tel-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Domicilio" v-model="agent.address"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Localidad" v-model="agent.locality"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Codigo postal" v-model="agent.postalCode"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Provincia" v-model="agent.province"></v-text-field> -->
              <v-select label="Provincia" :items="provincesItems" v-model="agent.province"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Ocupacion/Cargo" v-model="agent.occupation"></v-text-field>
            </v-col>
            <v-col>
              <v-select label="Resp. Frente AFIP" :items="ivaConditionsItems"
                        v-model="agent.ivaStatus.id"></v-select>
            </v-col>
            <v-col>
              <v-select label="Sujeto Obligado" :items="obligatedSubjectItems"
                        v-model="agent.obligatedSubject"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="3">
              <v-select label="PEP" :items="pepItems" v-model="agent.pep"></v-select>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field label="Password" v-model="agent.password"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4 mt-8">
          <div class="d-flex flex-row align-center justify-space-between">
            <h4>Datos cuenta bancaria</h4>
            <v-btn
              dark
              color="primary"
              @click="addBankAccount"
            >
              Agregar
            </v-btn>
          </div>

          <div v-for="(bankAccount, index) in agent.bankAccounts" :key="index" class="bank-account-container">
            <v-row class="mt-6">
              <v-col>
                <v-text-field label="Titular" v-model="bankAccount.owner"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Nr de cuenta" v-model="bankAccount.accountNumber"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="CBU" v-model="bankAccount.cbu"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Sucursal" v-model="bankAccount.branchOffice"></v-text-field>
              </v-col>
              <v-col>
                <v-select label="Banco" :items="banksItems" v-model="bankAccount.bank.id"></v-select>
              </v-col>
              <v-col>
                <v-select label="Tipo de cuenta" :items="bankAccountTypesItems"
                          v-model="bankAccount.accountType.id"></v-select>
              </v-col>
              <v-col>
                <v-select label="Moneda" :items="currenciesItems" v-model="bankAccount.currency.id"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col class="text-end">
                <v-btn
                  dark
                  color="primary"
                  @click="deleteAccount(index)"
                >
                  Eliminar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <div class="col-right">
        <v-card color=primary class="pa-4 ml-8 mb-8 d-flex flex-nowrap align-center">
          <h4 class="mr-4">Puntos GWINN: </h4>
          <v-text-field label="Puntos" v-model="agent.gwinnPoints"></v-text-field>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Tipo de usuario</h4>
          <v-select label="Tipo de agente" :items="userTypesItems"
                    v-model="agent.userType.id"></v-select>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Tipo de agente</h4>
          <v-select label="Tipo de agente" :items="agentTypesItems"
                    v-model="agent.agentType.id"></v-select>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Links de accesos</h4>
          <span>Google drive</span>
          <v-text-field label="Enlace" v-model="agent.driveLink"></v-text-field>
          <v-divider class="dashed mb-4"></v-divider>
          <span>Sistema de fidelizacion</span>
          <v-text-field label="Enlace" v-model="agent.fidelizacionLink"></v-text-field>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { createAgent, updateAgent, getAllAgentTypes } from '../services/agents'
import {
  getAllNationalities,
  getAllIVAConditions,
  getAllCurrencies,
  getAllBanks,
  getAllBankAccountTypes
} from '../services/global'
import ValidationHelper from '../helpers/ValidationHelper'

export default {
  name: 'Agent',
  data () {
    return {
      userTypesItems: [],
      nationalities: [],
      nationalitiesItems: [],
      estadoCivilItems: ['Soltero', 'Casado', 'Divorciado', 'Viudo', 'Otro'],
      ivaConditions: [],
      ivaConditionsItems: [],
      banks: [],
      banksItems: [],
      bankAccountTypes: [],
      bankAccountTypesItems: [],
      currencies: [],
      currenciesItems: [],
      agentTypes: [],
      agentTypesItems: [],
      agent: null,
      provincesItems: [
        'Buenos Aires (CABA)',
        'Buenos Aires (Interior)',
        'Catamarca',
        'Chaco',
        'Chubut',
        'Cordoba',
        'Corrientes',
        'Entre Rios',
        'Formosa',
        'Jujuy',
        'La Pampa',
        'La Rioja',
        'Mendoza',
        'Misiones',
        'Neuquen',
        'Rio Negro',
        'Salta',
        'San Juan',
        'San Luis',
        'Santa Cruz',
        'Santa Fe',
        'Santiago del Estero',
        'Tierra del Fuego',
        'Tucuman'
      ],
      obligatedSubjectItems: [
        {
          text: 'SI',
          value: 1
        },
        {
          text: 'NO',
          value: 0
        }
      ],
      pepItems: [
        {
          text: 'SI',
          value: 1
        },
        {
          text: 'NO',
          value: 0
        }
      ],
      showBirthDateDatepicker: null
    }
  },
  computed: {
    ...mapGetters(['agents', 'userToken']),
    agentIsEnterprise () {
      return this.agents !== null && this.agent.userType?.id === 2
    }
  },
  methods: {
    ...mapActions(['handleLoading']),
    initEmptyAgent () {
      this.agent = {
        address: '',
        bankAccounts: [],
        birthDate: '',
        businessData: {
          address: '',
          cuit: '',
          email: '',
          locality: '',
          name: '',
          phone1: '',
          phone2: '',
          postalCode: '',
          province: ''
        },
        civilStatus: '',
        dni: '',
        driveLink: '',
        email: '',
        fidelizacionLink: '',
        gwinnPoints: 0,
        ivaStatus: {},
        lastname: '',
        locality: '',
        name: '',
        nationality: {},
        obligatedSubject: 0,
        occupation: '',
        password: '',
        pep: 0,
        phone1: '',
        phone2: '',
        postalCode: '',
        province: '',
        referal: '',
        userType: {
          id: 2
        },
        agentType: {
          id: 3
        }
      }
    },
    initBusinessData () {
      this.agent.businessData = {
        address: '',
        cuit: '',
        email: '',
        locality: '',
        name: '',
        phone1: '',
        phone2: '',
        postalCode: '',
        province: ''
      }
    },
    filterNumbers (value) {
      this.agent.dni = value.replace(/[^0-9]/g, '')
    },
    capitalizeName () {
      if (this.agent.name) {
        this.agent.name = this.capitalizer(this.agent.name)
      }
    },
    capitalizeLastName () {
      if (this.agent.lastname) {
        this.agent.lastname = this.capitalizer(this.agent.lastname)
      }
    },
    capitalizer (string) {
      return string.toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase())
    },
    passValidations () {
      const validation = new ValidationHelper()
      const emailValidate = validation.validateEmail(this.agent.email)
      const businessEmailValidate = validation.validateEmail(this.agent.businessData.email)
      return (this.agentIsEnterprise) ? (emailValidate && businessEmailValidate) : emailValidate
    },
    saveAgent () {
      if (this.passValidations()) {
        if (this.agent.id !== undefined) {
          this.editAgent()
        } else {
          this.addAgent()
        }
      } else {
        alert('Complete correctamente todos los campos')
      }
    },
    async addAgent () {
      try {
        this.handleLoading(true)
        const res = await createAgent(this.userToken, this.agent)
        alert(res.data.response)
        // console.log(res)
      } catch (error) {
        console.log(error)
      } finally {
        this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async editAgent () {
      try {
        this.handleLoading(true)
        const res = await updateAgent(this.userToken, this.agent)
        alert(res.data.response)
        // console.log(res)
      } catch (error) {
        console.log(error)
      } finally {
        this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },

    addBankAccount () {
      this.agent.bankAccounts.push({
        accountNumber: '',
        accountType: {
          id: ''
        },
        bank: { id: '' },
        branchOffice: '',
        cbu: '',
        currency: { id: '' },
        owner: ''
      })
    },
    deleteAccount (index) {
      this.agent.bankAccounts.splice(index, 1)
    },

    initUserTypesItems () {
      this.userTypesItems = [
        {
          value: 1,
          text: 'Persona física'
        },
        {
          value: 2,
          text: 'Persona jurídica'
        }
      ]
    },
    async getNationalities () {
      try {
        // this.handleLoading(true)
        const res = await getAllNationalities()
        this.nationalities = this.sortNationalitiesArgFirst(res.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    sortNationalitiesArgFirst (nationalities) {
      const firstID = 10
      const nationalitiesSorted = nationalities.sort(function (x, y) { return x.id === firstID ? -1 : y.id === firstID ? 1 : 0 })
      return nationalitiesSorted
    },
    async getIVAConditions () {
      try {
        // this.handleLoading(true)
        const res = await getAllIVAConditions()
        this.ivaConditions = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getBanks () {
      try {
        // this.handleLoading(true)
        const res = await getAllBanks()
        this.banks = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getBankAccountTypes () {
      try {
        // this.handleLoading(true)
        const res = await getAllBankAccountTypes()
        this.bankAccountTypes = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getCurrencies () {
      try {
        // this.handleLoading(true)
        const res = await getAllCurrencies()
        this.currencies = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getAgentTypes () {
      try {
        // this.handleLoading(true)
        const res = await getAllAgentTypes()
        this.agentTypes = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    transformToSelect (array, fieldName, initItem) {
      const arrayItems = []
      if (initItem) {
        arrayItems.push(initItem)
      }
      array.forEach(data => {
        let text = ''
        if (typeof fieldName === 'string') {
          text = data[fieldName]
        }
        if (typeof fieldName === 'object') {
          fieldName.forEach(f => {
            text += data[f] + ' '
          })
        }
        arrayItems.push({
          value: data.id,
          text: text
        })
      })
      return arrayItems
    }
  },
  async mounted () {
    this.handleLoading(true)
    await this.getNationalities()
    await this.getIVAConditions()
    await this.getBanks()
    await this.getBankAccountTypes()
    await this.getCurrencies()
    await this.getAgentTypes()
    this.initUserTypesItems()
    this.handleLoading(false)

    const idAgent = this.$route.params.id
    if (idAgent !== undefined) {
      if (this.agents.length === 0) {
        this.$router.push({ path: '/agentes' })
      }
      const index = this.agents.findIndex(c => c.id === parseInt(idAgent))
      if (index !== -1) {
        this.agent = this.agents[index]
      }
    } else {
      this.initEmptyAgent()
    }
  },
  watch: {
    agent: {
      deep: true,
      handler () {
        if (this.agent.businessData === null) {
          this.initBusinessData()
        }
      }
    },
    'agent.name': {
      handler () {
        this.agent.password = this.agent.name + '2024'
      }
    },
    nationalities: {
      deep: true,
      handler () {
        this.nationalitiesItems = this.transformToSelect(this.nationalities, 'name', {
          value: '',
          text: 'Elija nacionalidad'
        })
      }
    },
    ivaConditions: {
      deep: true,
      handler () {
        this.ivaConditionsItems = this.transformToSelect(this.ivaConditions, 'name', {
          value: '',
          text: 'Elija Condicion IVA'
        })
      }
    },
    banks: {
      deep: true,
      handler () {
        this.banksItems = this.transformToSelect(this.banks, 'name', {
          value: '',
          text: 'Elija Banco'
        })
      }
    },
    bankAccountTypes: {
      deep: true,
      handler () {
        this.bankAccountTypesItems = this.transformToSelect(this.bankAccountTypes, 'name', {
          value: '',
          text: 'Elija tipo de cuenta bancaria'
        })
      }
    },
    currencies: {
      deep: true,
      handler () {
        this.currenciesItems = this.transformToSelect(this.currencies, 'name', {
          value: '',
          text: 'Elija Moneda'
        })
      }
    },
    agentTypes: {
      deep: true,
      handler () {
        this.agentTypesItems = this.transformToSelect(this.agentTypes, 'type', {
          value: '',
          text: 'Elija tipo de agente'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-info {
  .col-left {
    flex: 2;
  }

  .col-right {
    flex: 1;
    position: sticky;
    top: 0;
  }
}

.dashed {
  border: 1px dashed;
  opacity: 0.1;
}

.save-btn {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.bank-account-container {
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid $line-grey;
  }
}

.vue-tel-input-custom {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(255,255,255,0.7);
  padding-top: 12px;
  margin-top: 3px;

  :deep(.vue-tel-input-custom-class) {
    color: rgba(255,255,255,0.7);
  }

  :deep(.vti__dropdown.open) {
    background: #121212;
  }

  :deep(.vti__dropdown-list) {
    background: #121212;
    .vti__dropdown-item.highlighted {
      background: #1E1E1E;
    }
  }
}
</style>
